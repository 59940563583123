<template>
  <label class="input-wrap">
    <input
        v-model="searchValue"
        @input="$emit('input', searchValue)"
        class="custom-input"
        type="text"
        placeholder="Search ⌘/"
    />
    <v-icon class="input-icon" size="20px">mdi-magnify</v-icon>
  </label>

</template>

<script>
export default  {
  data() {
    return {
      searchValue: ''
    }
  }
}
</script>

<style lang="scss" scoped>
.input-wrap {
  position: relative;
  .custom-input {
    outline: none;
    padding: 10px 16px 10px 40px;
    background: #F1F3F5;
    border-radius: 12px;
    width: 100%;
  }
  .input-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 16px;
  }
}
</style>