<template>
  <label>
    <input
        class="custom-input"
        :type="inputType"
        :placeholder="placeholder"
    />
  </label>
</template>

<script>
export default {
  props: {
    inputType: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.custom-input {
  width: 100%;
  padding: 12px 24px;
  border-radius: 10px;
  border: 1px solid #002E4B;
  outline: none;

  &::placeholder {
    color: #002E4B;
  }

}
</style>