<template>
    <div>
        <canvas class="chart" ref="lineChart"></canvas>
    </div>
</template>

<script>
import Chart from 'chart.js'

export default {
    props: ['datasets', 'labels'],
    mounted () {
        const ctx = this.$refs.lineChart.getContext('2d')
        new Chart(ctx, {
            type: 'line',
            data: {
                labels: this.labels,
                datasets: this.datasets
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                height: 355,
                scales: {
                    xAxes: [{
                        gridLines: {
                            display: false
                        }
                    }],
                    yAxes: [{
                        gridLines: {
                            drawBorder: false,
                            drawOnChartArea: true,
                            borderDash: [5, 5]
                        },
                        ticks: {
                            max: 1100
                        }
                    }]
                },
                legend: {
                    display: false
                },
                elements: {
                    point: {
                        radius: 0
                    },
                    pointHover: {
                        backgroundColor: (context) => {
                            return context.dataset.backgroundColor
                        },
                        borderWidth: 1,
                        borderColor: (context) => {
                            return context.dataset.borderColor
                        },
                        hoverRadius: 10,
                        hitRadius: 10,
                        hoverBorderWidth: 1
                    }
                },
                tooltips: {
                    mode: 'nearest',
                    intersect: false,
                    callbacks: {
                        label: (tooltipItem, data) => {
                            let label = data.datasets[tooltipItem.datasetIndex].label || ''
                            let value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]
                            return label + ': ' + value
                        }
                    }
                }
            }
        })
    }
}
</script>

<style scoped>
.chart {
    height: 355px;
}
</style>
