<template>
  <v-app v-if="user" class="app-wrap" >
    <v-navigation-drawer
        app
        :clipped="clipped"
        :disable-resize-watcher="!$vuetify.breakpoint.smAndUp"
        fixed
        :width="drawerWidth"
    >
        <inline-svg :src="require('@/assets/imgs/logo.svg')"></inline-svg>
      <div class="nav-btns d-flex flex-column">
        <v-btn
            v-for="b in leftBtns"
            :key="b"
            icon
            fab
        >
          <inline-svg :src="b"></inline-svg>
        </v-btn>
      </div>
    </v-navigation-drawer>

    <v-app-bar
        v-if="user.verified"
        app
        :clipped-left="clipped"
        :elevation="elevation"
        color="white"
        dark
        height="90px"
    >
      <div class="links-wrap">
        <router-link
            v-for="l in links"
            :key="l.path"
            :to="l.path"
            class="top-links"
            :class="{ 'active': $route.path === l.path }"
        >
          {{ l.name }}
        </router-link>
      </div>

      <v-spacer></v-spacer>
      <v-menu offset-y>
        <template v-slot:activator="{ on }">
          <v-btn :ripple="false" color="transparent" elevation="0" class="profile-btn" v-on="on">
            ABC Ltd
          </v-btn>
        </template>
        <v-list>
          <v-list-item @click="logout">
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-btn :ripple="false" class="mr-4" icon @click="toggleMiniVariant">
        <v-icon color="black">mdi-menu</v-icon>
      </v-btn>

    </v-app-bar>
    <v-app-bar
      v-else
      app
      :clipped-left="clipped"
      :elevation="elevation"
      color="white"
      dark
      height="90px"
    >
      <div class="links-wrap">
        <router-link
            to="/"
            class="top-links"
            :class="{ 'active': $route.path === '/' }"
        >
          Welcome
        </router-link>
      </div>
    </v-app-bar>

    <v-main class="content-bg">
      <router-view></router-view>
    </v-main>
  </v-app>
  <v-app v-else class="app-wrap" >
    <v-main class="opposite-main">
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: 'AppLayout',
  components: {
  },
  data: () => ({
    clipped: false,
    drawer: null,
    drawerWidth: 106,
    elevation: 0,
    miniVariant: false,
    user: null,
    leftBtns: [
      require('@/assets/imgs/to.svg'),
      require('@/assets/imgs/tp.svg'),
      require('@/assets/imgs/res.svg'),
      require('@/assets/imgs/gtf.svg')
    ],
    links: [
      {
        name: "All",
        path: "/",
      },
      {
        name: "Credit Score",
        path: "/credit-score",
      },
      {
        name: "Trade Cycle",
        path: "/trade-cycle",
      },
      {
        name: "Suppliers",
        path: "/suppliers",
      },
      {
        name: "Buyers",
        path: "/buyers",
      },
      {
        name: "Country Risk Profile",
        path: "/country-profile-risk",
      }
    ]
  }),
  created() {
    if(localStorage.getItem('user')) {
      this.user = JSON.parse(localStorage.getItem('user'))
    }
  },
  computed: {
    showMainLayout() {
      return this.$route.path !== '/get-started' && this.$route.path !== 'authentication'
    }
  },
  methods: {
    toggleMiniVariant() {
      this.miniVariant = !this.miniVariant;
      this.drawer = !this.drawer;
    },
    logout() {
      localStorage.removeItem('user')
      window.location.reload()
    },
  },
};
</script>

<style lang="scss" scoped>
@import url('~@/assets/scss/century-gothic.scss');
@import url('~@/assets/scss/global.scss');
.app-wrap {
  font-family: "Century Gothic", sans-serif;
  color: #002E4B;
}
::v-deep .theme--light.v-navigation-drawer:not(.v-navigation-drawer--floating) .v-navigation-drawer__border {
  background-color: transparent;
}
::v-deep .v-navigation-drawer__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 16px 0;
}

.nav-btns {
  margin-top: 70px;
  .v-btn {
    &:nth-child(n):not(:last-child) {
      margin-bottom: 15px;
    }
  }
}

.profile-btn {
  background: transparent;
  text-transform: initial;
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;
  color: #002E4B;

}

.links-wrap {
  width: 100%;
  display: flex;
  gap: 30px;
  padding-left: 20px;
  .top-links {
    text-decoration: none;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 25px;
    color: #002E4B;
    &.active {
      font-weight: 700;
      position: relative;
      &:after {
        position: absolute;
        content: '';
        height: 2px;
        background: #00B1DB;
        width: 100%;
        bottom: -32px;
        left: 0;
      }
    }
  }
}

.content-bg {
  background: #eff3f9;
  padding: 20px;
}

::v-deep .v-main__wrap {
  padding: 30px;
}

::v-deep .v-tooltip__content {
  opacity: 1 !important;
  background: #ffffff;
  padding: 24px;
  gap: 24px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  color: #002E4B;
  border-radius: 12px;
  max-width: 300px;
  z-index: 10;
  pointer-events: auto !important;

  .tooltip-title {
    font-weight: 700;
    font-size: 30px;
    line-height: 37px;
    text-align: left;
    max-width: 80%;
    user-select: auto;
  }
  .tooltip-desc {
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    user-select: auto;
  }

  .tooltip-btn {
    text-transform: initial;
    color: #002E4B;
    background: #E3F5FF;
    border-radius: 12px;
    display: block;
    margin: 20px auto 0;
    cursor: pointer;
    user-select: auto;
    font-weight: 700;
    font-size: 20px;
    line-height: 25px;
    height: 41px;
  }
}

::v-deep .opposite-main {
  .v-main__wrap {
    padding: 0;
  }

}

</style>