import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    fontFamily: 'Century Gothic, sans-serif',
    useNextVariants: true,
    fonts: {
      body: 'Century Gothic, sans-serif',
    },
  },
});
