<template>
  <div v-if="user.verified"  class="home-wrap">
    <v-row>
      <v-col cols="8" class="col-wrap col-xl-9">
        <div class="col-content">
          <v-row>
            <v-col cols="8" >
              <h2 class="col-title">
                <span>Your Credit Score</span>
                <v-tooltip bottom v-model="showTooltip" ref="tooltip">
                  <template v-slot:activator="{ attrs }">
                    <v-btn
                        ref="tooltipActivator"
                        class="tooltip-btn"
                        icon
                        v-bind="attrs"
                        @click="showTooltip = !showTooltip"
                    >
                      <v-icon color="#DCE1E7">mdi-information-outline</v-icon>
                    </v-btn>
                  </template>
                  <div class="tooltip-content" @click.stop>
                    <h4 class="tooltip-title mb-4">About Credit Passport</h4>
                    <p class="tooltip-desc mb-5">
                      The Credit Passport assessment indicates the ability
                      of a company to perform its financial obligations in a rolling twelve months from today's date.
                    </p>
                    <p class="tooltip-desc">
                      Research and InnovationCredit Passport is the result of advanced mathematical models,
                      and an expertise in the dynamic sector of small and medium business.
                    </p>
                    <v-btn
                        @click.stop="$router.push('/credit-score')"
                        depressed
                        class="tooltip-btn"
                    >
                      Learn more
                    </v-btn>
                  </div>
                </v-tooltip>
              </h2>
              <v-img width="85%" class="credit-indicator" :src="require('@/assets/imgs/credit-score.svg')"></v-img>
              <p class="mini-title text-center">Your Credit Score is <span class="cs-value">{{creditScore}} DBT</span></p>
            </v-col>
            <v-col cols="4" class="d-flex flex-column justify-space-around">
              <div class="award-block">
                <h4 class="award-title">
                  <inline-svg class="mr-3" :src="require('@/assets/imgs/award.svg')"></inline-svg>
                  Only 1 out of 41
                </h4>
                <p class="award-desc">companies achieves the same level of credit quality</p>
              </div>
              <div class="congrats">
                <v-img class="congrats-svg" :src="require('@/assets/imgs/Intersect.svg')"></v-img>
                <h4 class="congrats-title">Congratulations</h4>
                <p class="congrats-desc">
                  Your credit profile has been matched with suitable lending products!
                </p>
                <v-btn depressed class="congrats-btn">Find out more</v-btn>
              </div>

            </v-col>
          </v-row>

        </div>

      </v-col>
      <v-col cols="4" class="col-wrap col-xl-3">
        <div class="col-content h-100 cr-col">
          <h2 class="col-title">Country Risk Profile</h2>
          <div>
            <p class="cr-desc">
              Country risk refers to the economic, social, and political conditions in a foreign country
              that may adversely affect a financial institution's operations.
            </p>
            <p class="cr-country">
              Ease of doing business in
            </p>
            <div class="grey-blocks">
              <div class="left">
                <div >
                  <v-select
                      class="country-select"
                      v-model="country"
                      :items="countries"
                      item-text="name"
                      item-value="code"
                      label="Select a country"
                      solo
                      flat
                      color="black"
                  />
                </div>
                <div class="d-flex align-center flag-wrap">
                  <country-flag :country='country.toLowerCase()' size='big' />
                  <span class="country-name">{{ getFullCountryName(country) }}</span>
                </div>
              </div>
              <div class="right">
                <p>Score</p>
                <p class="arrow-wrap">
                  <inline-svg :src="require('@/assets/imgs/arrow-up.svg')"></inline-svg>
                  <span class="country-rate">63</span>
                </p>

              </div>
            </div>
          </div>

          <v-btn depressed class="main-btn cr-btn">Explore Options</v-btn>
        </div>
      </v-col>
      <v-col cols="6" class="col-wrap">
        <div class="col-content">
          <div class="d-flex justify-space-between">
            <h2 class="col-title">Suppliers</h2>
            <v-btn @click="suppliersDialog = true" depressed class="main-btn mx-0">View all</v-btn>
          </div>
          <div class="custom-table-wrap">
            <TableComponent class="mt-25" :headers="headers" :items="suppliers"/>
          </div>
        </div>
      </v-col>
      <v-col cols="6" class="col-wrap">
        <div class="col-content">
          <div class="d-flex justify-space-between">
            <h2 class="col-title">Customers</h2>
            <v-btn @click="customersDialog = true" depressed class="main-btn mx-0">View all</v-btn>
          </div>
          <TableComponent class="mt-25" :headers="headers" :items="suppliers"/>
        </div>
      </v-col>
    </v-row>
    <div class="sc-dialogs">
      <v-dialog
          :position-x="'right'" :right="0" :transition="'slide-x-reverse-transition'"
          v-model="suppliersDialog"
          max-width="700px"
          width="100%"
          overlay-color="#002E4B"
          overlay-opacity="0.3"
      >
        <v-card class="dialog-card">
          <v-card-title class="card-title">
            <v-btn @click="suppliersDialog = false" icon>
              <inline-svg :src="require('@/assets/imgs/chevrons-right.svg')"></inline-svg>
            </v-btn>
            <h3 class="sc-dialog-title">
              Suppliers
            </h3>
          </v-card-title>
          <v-card-text>
            <div class="dialog-filters">
              <div class="d-flex align-center">
                <InputComponent v-model="filteredText" class="input-filter" />
                <FilterBtn />
              </div>
              <v-btn height="42px" depressed class="main-btn mx-0"><v-icon>mdi-plus</v-icon>Add</v-btn>
            </div>
            <div class="custom-table-wrap">
              <div class="table-tabs">
                <v-btn
                    @click="suppliersActiveTab = 'month'"
                    plain
                    :ripple="false"
                    depressed
                    class="table-f-btn"
                    :class="{
                      'table-f-btn-active': suppliersActiveTab === 'month'
                    }"
                >
                  Last 30 days
                </v-btn>
                <span>|</span>
                <v-btn
                    @click="suppliersActiveTab = 'all'"
                    plain
                    :ripple="false"
                    depressed
                    class="table-f-btn"
                    :class="{
                      'table-f-btn-active': suppliersActiveTab === 'all'
                    }"
                >
                  All
                </v-btn>
              </div>

              <TableComponent :filter-text="filteredText" class="table-dialog" :headers="headers" :items="suppliers"/>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog
          :position-x="'right'" :right="0" :transition="'slide-x-reverse-transition'"
          v-model="customersDialog"
          max-width="700px"
          width="100%"
          overlay-color="#002E4B"
          overlay-opacity="0.3"
      >
        <v-card class="dialog-card">
          <v-card-title class="card-title">
            <v-btn @click="customersDialog = false" icon>
              <inline-svg :src="require('@/assets/imgs/chevrons-right.svg')"></inline-svg>
            </v-btn>
            <h3 class="sc-dialog-title">
              Customers
            </h3>
          </v-card-title>
          <v-card-text>
            <div class="dialog-filters">
              <div class="d-flex align-center">
                <InputComponent v-model="filteredText" class="input-filter"/>
                <FilterBtn />
              </div>
              {{filteredText}}
              <v-btn height="42px" depressed class="main-btn mx-0"><v-icon>mdi-plus</v-icon>Add</v-btn>
            </div>
            <div class="custom-table-wrap">
              <div class="table-tabs">
                <v-btn
                    @click="customersActiveTab = 'month'"
                    plain
                    :ripple="false"
                    depressed
                    class="table-f-btn"
                    :class="{
                      'table-f-btn-active': customersActiveTab === 'month'
                    }"
                >
                  Last 30 days
                </v-btn>
                <span>|</span>
                <v-btn
                    @click="customersActiveTab = 'all'"
                    plain
                    :ripple="false"
                    depressed
                    class="table-f-btn"
                    :class="{
                      'table-f-btn-active': customersActiveTab === 'all'
                    }"
                >
                  All
                </v-btn>
              </div>
              <TableComponent :filter-text="filteredText" class="table-dialog" :headers="headers" :items="suppliers"/>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
  </div>
  <div v-else class="home-wrap">
    <WelcomeBlock />
  </div>
</template>

<script>
import CountryData from '@/mixins/countryData'
import TableComponent from "@/components/TableComponent";
import InputComponent from "@/components/InputComponent";
import FilterBtn from "@/components/FilterBtn";
import WelcomeBlock from "@/components/WelcomeBlock";

export default {
  name: 'Home',
  mixins: [CountryData],
  components: {
    TableComponent,
    InputComponent,
    FilterBtn,
    WelcomeBlock
  },
  data() {
    return {
      filteredText: '',
      suppliersDialog: false,
      customersDialog: false,
      showTooltip: false,
      suppliersActiveTab: 'month',
      customersActiveTab: 'month',
      creditScore: 15,
      user: null,
      country: 'US',
      headers: [
        {
          text: 'Supplier Name',
          value: 'name',
        },
        {
          text: 'Country',
          value: 'country',
        },
        {
          text: 'Risk',
          value: 'risk',
        },
        {
          text: 'Insights & Insure',
          value: 'insight',
        },
      ],
      suppliers: [
        {
          name: "Apple",
          country: "Ukraine",
          risk: "Low",
          insight: "Insights & Insure",
          logo: require('@/assets/imgs/res.svg')
        },
        {
          name: "Discord",
          country: "England",
          risk: "Low",
          insight: "Insights & Insure",
          logo: require('@/assets/imgs/res.svg')
        },
        {
          name: "Google",
          country: "Cz",
          risk: "High",
          insight: "test",
          logo: require('@/assets/imgs/res.svg')
        },
        {
          name: "Company Name",
          country: "Ukraine",
          risk: "Low",
          insight: "Insights & Insure",
          logo: require('@/assets/imgs/res.svg')
        },
        {
          name: "Company Name",
          country: "Ukraine",
          risk: "Low",
          insight: "Insights & Insure",
          logo: require('@/assets/imgs/res.svg')
        }
      ],
    }
  },
  created() {
    if (localStorage.getItem('user')) {
      this.user = JSON.parse(localStorage.getItem('user'))
    }

  },
  mounted() {
    document.addEventListener("click", this.closeTooltip);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.closeTooltip);
  },
  methods: {
    getFullCountryName(selectedCountry) {
      let country = ''
      this.countries.forEach(c => {
        if (c.code === selectedCountry) {
          country = c.name
        }
      })
      return country
    },
    closeTooltip(event) {
      if (!this.$refs.tooltip.$el.contains(event.target) && !this.$refs.tooltipActivator.$el.contains(event.target)) {
        this.showTooltip = false;
      }
    },
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/pages/home.scss";
</style>