<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on }">
      <v-btn height="42px" :ripple="false" depressed class="filter-btn" v-on="on">
        <inline-svg class="mr-2" :src="require('@/assets/imgs/filter.svg')"></inline-svg>
        Filters
      </v-btn>
    </template>
    <v-list>
      <v-list-item v-for="(item, index) in items" :key="index" @click="selectItem(item)">
        <v-list-item-title>{{ item }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  data() {
    return {
      items: ['Option 1', 'Option 2', 'Option 3'],
      selectedItem: null
    }
  },
  methods: {
    selectItem(item) {
      this.selectedItem = item
    }
  }
}
</script>

<style lang="scss" scoped>
.filter-btn {
  margin-left: 12px;
  padding: 10px 16px;
  text-transform: initial;
  background: #F1F3F5;
  border-radius: 12px;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: #999999;
}
</style>