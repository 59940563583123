import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home'
import CreditScore from "@/views/CreditScore";
import Auth from "@/views/Auth";
import ComingSoon from "@/views/ComingSoon";

Vue.use(VueRouter)
const isAuthenticated = () => {
  return localStorage.getItem('user') !== null;
};

const authGuard = (to, from, next) => {
  if (isAuthenticated()) {
    next({ path: '/authentication' });
  } else {
    next();
  }
};
const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { requiresAuth: true }
  },
  {
    path: '/authentication',
    name: 'Authentication',
    component: Auth,
    beforeEnter: authGuard
  },
  {
    path: '/credit-score',
    name: 'Credit Score',
    component: CreditScore,
    meta: { requiresAuth: true },
  },
  {
    path: '/trade-cycle',
    name: 'Trade Cycle',
    component: ComingSoon,
    meta: { requiresAuth: true },
  },
  {
    path: '/suppliers',
    name: 'Suppliers',
    component: ComingSoon,
    meta: { requiresAuth: true },
  },
  {
    path: '/buyers',
    name: 'Buyers',
    component: ComingSoon,
    meta: { requiresAuth: true },
  },
  {
    path: "/country-profile-risk",
    name: "Country Risk Profile",
    component: ComingSoon,
    meta: { requiresAuth: true },
  },
]


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.name === 'Home' && !isAuthenticated()) {
    next({ path: '/authentication' });
  } else if (to.matched.some(record => record.meta.requiresAuth)) {
    if (isAuthenticated()) {
      next();
    } else {
      next({ path: '/authentication' });
    }
  } else {
    next();
  }
});


export default router
