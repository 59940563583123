<template>
  <div class="custom-table-wrap">
    <v-data-table
        :headers="headers"
        :items="filteredItems"
        hide-default-footer
        class="elevation-0 table-sc"
        disable-sort
    >
      <template v-slot:item.name="{ item }">
        <v-list-item class="px-0">
          <v-img
              max-width="20px"
              max-height="20px"
              height="20px"
              class="mr-3"
              :src="item.logo"
              :alt="`${item.name} logo`"
          />
          <v-list-item-content>
            <v-list-item-title>{{ item.name }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  props: {
    headers: {
      type: Array,
      default: () => []
    },
    items: {
      type: Array,
      default: () => []
    },
    filterText: {
      type: String,
      default: ''
    }
  },
  data() {
    return {

    }
  },
  computed: {
    filteredItems() {
      if (!this.filterText) {
        return this.items;
      }
      const filterTextLower = this.filterText.toLowerCase();
      return this.items.filter(item => {
        return Object.keys(item).some(key => {
          return String(item[key]).toLowerCase().includes(filterTextLower);
        });
      });
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep thead > tr > th{
  color: #002E4B !important;
  font-weight: 700 !important;
  font-size: 15px !important;
  line-height: 18px !important;
}
::v-deep tbody > tr > td {
  font-weight: 400 !important;
  font-size: 15px !important;
  line-height: 18px !important;
  color: #002E4B !important;
}
.table-sc {
  background: #F1F3F5;
  border-radius: 12px;
}
</style>
