<template>
  <v-row v-if="started" class="pa-0">
    <v-col cols="7" class="left-col">
      <div class="gs-content">
        <h1 class="gs-title gs-title__top">Hello!</h1>
        <p class="gs-desc mb-25">Welcome to the TFG Trade Finance hub.</p>
        <p class="gs-desc mb-0">Find out how we can help you access trade finance
          to increase your cross-border imports and exports or
          learn about trade finance.
        </p>
        <v-btn depressed @click="started = false" class="gs-btn">Get Started</v-btn>
      </div>
    </v-col>
    <v-col cols="5" class="right-col-1">
      <div class="right-img">
      </div>
    </v-col>
  </v-row>
  <v-row v-else class="pa-0">
    <v-col cols="7" class="left-col">
      <div class="gs-content">
        <h1 class="gs-title">Log in</h1>
        <div>
          <AuthInput input-type="email" placeholder="Email Address" />
        </div>
        <div class="mt-5">
          <AuthInput  input-type="password" placeholder="Password" />
        </div>
        <div>
          <v-btn depressed @click="login" class="gs-btn mr-4">Log in</v-btn>
          <v-btn depressed class="gs-btn gs-btn__su">Sign up</v-btn>
        </div>
      </div>
    </v-col>
    <v-col cols="5" class="right-col">
      <div class="right-img">
      </div>
    </v-col>
  </v-row>
</template>

<script>
import AuthInput from "@/components/AuthInput";
export default {
  components: {
    AuthInput
  },
  data() {
    return {
      started: true,
    }
  },
  methods: {
    login() {
      localStorage.setItem('user', JSON.stringify({
          name: 'random',
          verified: false,
        }))
      this.$router.push('/')
      window.location.reload()
    }
  }
}
</script>

<style lang="scss" scoped>
.left-col {
  padding: 100px;
  .gs-content {
    .gs-title {
      font-weight: 700;
      font-size: 50px;
      line-height: 61px;
      margin-bottom: 100px;
      &__top {
        margin-bottom: 70px;
      }
    }
    .gs-desc {
      font-weight: 400;
      font-size: 18px;
      line-height: 25px;
      color: #000000;
      max-width: 480px;
    }
    .gs-btn {
      margin-top: 80px;
      padding: 16px 56px;
      height: 57px;
      background: #002E4B;
      border-radius: 12px;
      font-weight: 700;
      font-size: 20px;
      line-height: 25px;
      text-align: center;
      text-transform: initial;
      color: #FFFFFF;
      &__su {
        background:transparent !important;
        border: 1px solid #002E4B;
        color: #002E4B;
      }
    }
  }
}
.right-col {
  height: 100vh;
  .right-img {
    background-size: cover !important;
    height: 100vh;
    background: url('~@/assets/imgs/login.png') no-repeat right center;
  }
}
.right-col-1 {
  height: 100vh;
  .right-img {
    /* Center and scale the image nicely */
    background-size: cover !important;
    height: 100vh;
    background: url('~@/assets/imgs/welocme-1.png') no-repeat center;
  }
}
</style>