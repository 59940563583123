<template>
    <div class="overview-chart">
        <div class="d-sm-flex justify-space-between align-center mb-12">
            <div class="text-header">Overview</div>
            <div class="d-flex">
                <div class="d-flex justify-space-between mr-8">
                    <div class="d-flex justify-space-between align-center mr-4">
                        <div class="income"></div>
                        <div class="ml-2">Income</div>
                    </div>
                    <div class="d-flex justify-space-between align-center">
                        <div class="expenses"></div>
                        <div class="ml-2">Expenses</div>
                    </div>
                </div>
                <div class="overview-chart__frequency">Monthly</div>
            </div>
        </div>
        <LineChart :datasets="datasets" :labels="labels"></LineChart>
    </div>
</template>

<script>
import LineChart from './LineChart.vue'

export default {
    components: {
        LineChart
    },
    data () {
        return {
            datasets: [
                {
                    label: 'Income',
                    backgroundColor: 'transparent',
                    borderColor: '#1FCB4F',
                    data: this.generateData(),
                    fill: false,
                    cubicInterpolationMode: 'monotone'
                },
                {
                    label: 'Expenses',
                    backgroundColor: 'transparent',
                    borderColor: '#FFC01E',
                    data: this.generateData(),
                    fill: false,
                    cubicInterpolationMode: 'monotone'
                },
            ],
            labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
        }
    },
    methods: {
        generateData () {
            const data = []
            for (let i = 0; i < 12; i++) {
                data.push(Math.floor(Math.random() * (1100 + 1)))
            }
            return data
        }
    }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/components/overview-chart.scss";
</style>

