<template>
  <v-row>
    <v-col cols="5">
      <div class="hello-block">
        <h1 class="h-title">Hello!</h1>
        <p class="h-desc mb-25">Welcome to the TFG Trade Finance hub.</p>
        <p class="h-desc mb-0">Find out how we can help you access trade finance
          to increase your cross-border imports and exports or
          learn about trade finance.
        </p>
        <v-btn depressed class="w-btn">Explore</v-btn>
      </div>
    </v-col>
    <v-col cols="4" class="col-xl-3">
      <div class="upgrade-block">
        <div>
          <p class="f-title">Upgrade</p>
          <h3 class="main-title">Trade Finance Plus</h3>
          <p class="desc">Share your score with the website badge and learn how to maintain a financially stable company</p>
        </div>
        <div class="d-flex justify-center">
          <v-btn depressed class="u-btn">Learn more</v-btn>
        </div>
      </div>
    </v-col>
    <v-col cols="3" class="col-xl-2">
      <div class="verify-block">
        <div>
          <h3 class="v-title">
            Verify your account
          </h3>
          <p class="v-desc">
            Verify your account to have fully access to
            your finance hub.
          </p>
        </div>
        <div class="d-flex justify-center">
          <v-btn @click="verifyAccount" depressed class="w-btn">Verify</v-btn>
        </div>

      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name:"WelcomeBlock",
  methods: {
    verifyAccount() {
      localStorage.setItem('user', JSON.stringify({
        name: 'user',
        verified: true
      }))
      window.location.reload()
    }
  }
}
</script>

<style lang="scss" scoped>
.w-btn {
  margin-top: 80px;
  padding: 16px 56px !important;
  height: 57px !important;
  background: #002E4B !important;
  border-radius: 12px;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  text-align: center;
  text-transform: initial;
  color: #FFFFFF;
  width: 100%;
  display: block;
  max-width: 184px;
}
.hello-block {
  padding: 32px;
  background: #FFFFFF;
  border-radius: 12px;
  .h-title {
    font-weight: 700;
    font-size: 50px;
    line-height: 61px;
    margin-bottom: 20px;
  }
  .h-desc {
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    color: #000000;
    max-width: 480px;
  }
}

.upgrade-block {
  background: #002E4B;
  border-radius: 12px;
  padding: 32px;
  color: #FFFFFF;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .f-title {
    font-weight: 700;
    font-size: 15px;
    line-height: 18px;
  }
  .main-title {
    font-weight: 700;
    font-size: 30px;
    line-height: 37px;
  }
  .desc {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 0;
    margin-top: 20px;
  }
  .u-btn {
    padding: 16px 56px;
    text-transform: initial;
    background: #FFFFFF;
    border-radius: 12px;
    height: 57px;
    font-weight: 700;
    font-size: 20px;
    line-height: 25px;
    color: #002E4B;
    max-width: 222px;
  }
}

.verify-block {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  background: #E3F5FF;
  border-radius: 12px;
  padding: 32px;
  .v-title {
    font-weight: 700;
    font-size: 30px;
    line-height: 37px;
  }
  .v-desc {
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    margin-bottom: 0;
    margin-top: 20px;
  }
}
</style>