<template>
  <div class="credit-score">
    <h1 class="text-header mb-5">Your Credit Score</h1>
    <div class="d-flex justify-space-between credit-score__congrats">
      <div class="px-9 py-10">
        <div class="text-uppercase mb-10 text-header text-white">
          Congratulations
        </div>
        <div class="text-h5 text-white mb-10" style="max-width: 420px">
          Your credit profile has been matched with suitable lending products.
        </div>
        <div class="button--dark px-4 py-2">Find out more</div>
      </div>
      <inline-svg :src="require('@/assets/imgs/intersect-right.svg')" />
    </div>
    <div class="d-sm-flex justify-space-between mt-9">
      <v-card class="px-4 py-6 mr-sm-3 mb-6 mb-sm-0 w-50 credit-score__card">
        <h2 class="text-header mb-10">Your Credit Score</h2>
        <div class="d-flex justify-center px-4">
          <img
            class="mb-7"
            width="100%"
            height="360px"
            :src="require('@/assets/imgs/credit-score.svg')"
          />
        </div>
        <p class="mini-title text-center">
          Your Credit Score is <span class="font-weight-bold">15 DBT</span>
        </p>
      </v-card>
      <div class="ml-sm-3 w-50">
        <v-card
          class="py-8 px-6 d-flex justify-space-between credit-score__card"
        >
          <div class="credit-score__card__info">
            <div class="text-center">Last Update</div>
            <div class="text-center font-weight-bold">26.11.2022</div>
          </div>
          <div class="credit-score__card__info">
            <div class="text-center">Valid till</div>
            <div class="text-center font-weight-bold">26.11.2022</div>
          </div>
          <div>
            <div class="text-center">Next Update</div>
            <div class="text-center font-weight-bold">in 4 days</div>
          </div>
        </v-card>
        <v-card class="mt-8 pa-6 credit-score__card">
          <overview-chart />
        </v-card>
      </div>
    </div>
    <div class="d-sm-flex justify-space-between mt-9">
      <div class="mr-sm-3 w-50 mb-10 mb-sm-0">
        <v-card
          class="pa-6 mb-8 d-flex justify-space-between align-center credit-score__card"
        >
          <div>
            <div class="card-header mb-2">Only 1 out of 41</div>
            <div style="max-width: 285px">
              companies achieves the same level of credit quality
            </div>
          </div>
          <inline-svg :src="require('@/assets/imgs/award-icon.svg')" />
        </v-card>
        <v-card
          class="pt-6 pl-6 pb-10 credit-score__card credit-score__card--dark"
        >
          <div class="tag mb-4">Upgrade</div>
          <div class="text-header text-white mb-2">Trade Finance Plus</div>
          <div class="text-white mb-6" style="max-width: 270px">
            Share your score with the website badge and learn how to maintain a
            financially stable company
          </div>
          <div style="width: 148px" class="button--dark px-4 py-2">
            Learn more
          </div>
        </v-card>
      </div>
      <div class="ml-sm-3 w-50">
        <div class="d-sm-flex justify-space-between">
          <v-card class="pa-6 position-relative w-50 mb-4 mb-sm-0 mr-sm-4 credit-score__card">
            <div
              class="text-header mb-6"
              style="line-height: 37px; max-width: 250px"
            >
              About Credit Passport
            </div>
            <div class="mb-9 description">
              The Credit Passport assessment indicates the ability of a company
              to perform its financial obligations in a rolling twelve months
              from today's date.
              <br />
              <br />
              Research and InnovationCredit Passport is the result of advanced
              mathematical models, and an expertise in the dynamic sector of
              small and medium business.
            </div>
            <div class="button px-4 py-2">Learn more</div>
          </v-card>
          <v-card class="pa-0 w-50 ml-sm-4 position-relative credit-score__card">
            <img
              width="100%"
              height="165px"
              class="mb-4"
              :src="require('@/assets/imgs/blog.svg')"
            />
            <div class="pa-6 pt-0">
              <div class="text-header mb-6" style="line-height: 37px">
                What is a business credit score
              </div>
              <div class="mb-9 description">
                Understand how a business credit score works and how you can
                unlock the flow of opportunity for your business.
                  <br />
                  <br />
                  <br />
              </div>
              <div class="button px-4 py-2">Read blog</div>
            </div>
          </v-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OverviewChart from "@/components/OverviewChart.vue";

export default {
  components: { OverviewChart },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/pages/credit-score.scss";
</style>
